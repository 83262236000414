body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #100e12;
}

@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Extralight.woff2") format("woff2"),
    url("./font/ClashDisplay-Extralight.woff") format("woff"),
    url("./font/ClashDisplay-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Semibold.woff2") format("woff2"),
    url("./font/ClashDisplay-Semibold.woff") format("woff"),
    url("./font/ClashDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Medium.woff2") format("woff2"),
    url("./font/ClashDisplay-Medium.woff") format("woff"),
    url("./font/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Bold.woff2") format("woff2"),
    url("./font/ClashDisplay-Bold.woff") format("woff"),
    url("./font/ClashDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Regular.woff2") format("woff2"),
    url("./font/ClashDisplay-Regular.woff") format("woff"),
    url("./font/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("./font/ClashDisplay-Light.woff2") format("woff2"),
    url("./font/ClashDisplay-Light.woff") format("woff"),
    url("./font/ClashDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
.MuiButton-contained.Mui-disabled {
  // color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgb(255 255 255) !important;
}
.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayAlign {
  display: flex;
  align-items: center;
}
.displayStart {
  display: flex;
  align-items: center;
  justify-content: start;
}
.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.displayColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoImg {
  width: 180px;
}

// * {
//   scrollbar-width: 0px;
//   scrollbar-width: none;
// }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-track {
  background: transparent; 
}
 
::-webkit-scrollbar-thumb {
  background: #FFFFFF1A; 
}


.textfiledicons {
  .MuiIconButton-root {
    padding: 6px;
    font-size: 17px;
    margin-left: -4px;
    border-radius: 8px;
    margin-right: 8px;
  }
}

.apexcharts-gridline {
  stroke: #ffffff0d;
}
.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-tap-highlight-color: transparent;
  backdrop-filter: blur(2px);
}

button + button {
  margin-left: 10px !important;
}
